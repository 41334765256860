/* Accessibility - adding classes and media queries to address magnification bugs
@media (800px <=width <=1024px) {
  .ms-Dropdown-container>.ms-Label {
    font-size: smaller;
  }
} */

/* @media (200px <=width <=1100px) {
  .ms-Stack {
    display: flex;
    flex-direction: column;
  }

  .ms-Dropdown {
    width: auto;
  }

  .ms-Dropdown-container>.ms-Label {
    font-size: x-small;
  }
}

@media (751px <=width <=1100px) {
  .ms-Dropdown-container {
    width: 65%;
  }
}

@media (600px <=width <=725px) {
  .ms-Dropdown-container {
    width: 60%;
  }
}

@media (415px <=width <=500px) {
  .ms-Dropdown-container {
    width: 39%;
  }
}

@media (360px <=width <=415px) {
  .ms-Dropdown-container {
    width: 39%;
  }
}

@media (width <=300px) {
  .ms-Dropdown-container {
    width: 26%;
  }
}

@media(width >=672px) {
  .ms-dialogMainOverride {
    min-width: 672px;
  }

  .AddCaseOverlayStylesSearchButton {
    left: 450px;
  }

  .AddCaseOverlayStylesClearButton {
    left: 425px;
  }

  .AddCaseOverlaySingleSearchButton {
    left: 545px;
  }
}

@media (width < 672px) {

  .AddCaseOverlayStylesClearButton,
  .AddCaseOverlayStylesSearchButton,
  .AddCaseOverlaySingleSearchButton {
    left: 0px;
  }
}

Accessibility fix for navigation menu when zoom level is 200
@media (width < 800px) {

  button[role="menuitem"] div i,
  a[role="menuitem"][name="Dashboard"] div i,
  a[role="menuitem"][name="Discounts"] div i,
  a[role="menuitem"][name="New CP Strategic Case"] div i,
  a[role="menuitem"][name="New Case Submission"] div i,
  a[role="menuitem"][name="Claims"] div i,
  a[role="menuitem"][name="Help + Support"] div i {
    font-size: 10px;
    margin-right: 8px;
  }

  nav[role="navigation"] {
    width: 25%;
  }
} */

#mdppAccessibilityGridContainer, #mdppAccessibilityNextGenGridContainer {
  font-size: 14px;
}

#mdppAccessibilityGridContainer .k-grid-toolbar, #mdppAccessibilityGridContainer .k-grid-pager, 
#mdppAccessibilityNextGenGridContainer .k-grid-toolbar, #mdppAccessibilityNextGenGridContainer .k-grid-pager{
  border-color: rgba(0, 0, 0, 0.08);
  border-top-color: rgba(0, 0, 0, 0.08);
  border-right-color: rgba(0, 0, 0, 0.08);
  border-bottom-color: rgba(0, 0, 0, 0.08);
  border-left-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;

  border-width: 1px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-style: solid;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  box-sizing: border-box;
  line-height: 1.4285714286;
}

/* Accessibility - handles table sizing and letter spacing at resolutions below 720p */
@media (width < 80em) {
  .k-grid-header .k-header>.k-link {
    text-overflow: unset;
  }

  .k-grid-content table,
  .k-grid-content-locked table {
    table-layout: fixed;
  }

  table,
  tbody {
    overflow: auto;
  }

  th,
  col {
    min-width: 40ch;
    max-width: 54ch;
  }
}

/*Accessibility fix for Grid column headers not visible*/
@media (width < 81em) {
  [role="tablist"]{
    display: grid;
  }

  #parentDiv{
    width: 95%;
  }

  .k-widget.k-window.k-dialog{
    width: 60%;
  }

  [class*="DialogueCheckBox"]{
    width: 55%;
  }

  .k-grid-toolbar.k-toolbar div{
    width: 100%;
  }

  .k-dialog-buttongroup.k-actions.k-hstack.k-justify-content-stretch div{
    justify-content: flex-start;
  }

  #mdppAccessibility-action-menu.ms-Stack{
    width: 100%;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
  }

  #mdppAccessibility-cardStack{
    overflow-x: auto;
  }

  .k-grid-header .k-header.k-grid-header-sticky, .k-grid-header .k-filter-row .k-grid-header-sticky, .k-grid-content-sticky, .k-grid-row-sticky, .k-grid-footer-sticky{
    z-index: auto;
  }

  #leftnav nav[role="navigation"] {
    background-color: #333333;
    overflow:auto;
  }

  a.ms-Link, button.ms-Link {
    z-index: auto;
  }

  div#RefreshIconButton {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ms-Stack button.ms-Button--commandBar span span.ms-Button-textContainer{
    display: none;
  }
}

@media (width < 321px){
  .k-widget.k-window.k-dialog{
    width: 98%;
    height: inherit;
  }

  

  div.k-pager-wrap.k-pager.k-widget.k-grid-pager[role="navigation"]{
    display: initial;
  }
}

body {
  margin: 0;
  font-family: "Segoe UI" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div[role="banner"] {
  background-color: #333333;
  /*    position: fixed;
    margin-bottom: 30px;
    min-width: 100vw;*/
}
div i, a[role="menuitem"][name="CP Strategic"], div i,
a[role="menuitem"][name="OTLE"], div i,
a[role="menuitem"][name="DR|SP"] {
    right: -20px;
}
div i, a[role="menuitem"] [name="CAS Submission"] {
    right: 5px;
}
/*Accessibility Color contrast fixes */
.k-textbox:focus {
    border-color: rgba(0, 0, 0, 0.5);
    outline-color: #171717;
    outline: 2px transparent solid;
}
.k-pager-sizes .k-label ,.k-dropdown .k-dropdown-wrap:focus {
  border-color: rgba(0, 0, 0, 0.5);
  outline-color: #171717;
  outline: 2px transparent solid;
}

.k-button:focus {
  border-color: currentColor;
  border-width: 2px;
}

#ProductSelectionViewGrids .k-panelbar.k-panelbar-header:focus {
  border-color: rgba(0, 0, 0, 0.5);
  outline-color: #171717;
  outline: 2px transparent solid;
}

/*setting heading size */
div[class='DRStyleExpand-197'] h1 {
  font-size: 20px;
}

/* Setting outline for heading on focus*/
div[class='DRStyleExpand-197'] h1:focus {
  outline: none;
}

/* setting header banner widths, 4fr = microsoft logo and MDPP text and 0fr = search, 
  1fr = settings,profile, help  */
#root div[class*="root-"][role="banner"] {
  grid-template-columns: 2fr 0fr 1fr;
}

/* nav[role="navigation"] {
  background-color: #333333;
  min-height: 93vh;
  overflow: hidden;
  position: fixed;
  padding-top: 48px;
} */

#main {
  overflow: hidden;
}

#footerPadding {
  height: 60px;
}

#emptyHeight10 {
  height: 10px;
}

#emptyHeight12 {
  height: 12px;
}

#emptyHeight20 {
  height: 30px;
}

#emptyHeight31 {
  height: 31px;
}

#emptyHeight50 {
  height: 50px;
}

.k-grid-header thead {
  height: 43px;
  background-color: #ffffff;
}

.k-grid-header .k-header {
  color: #333333;
  height: 32px;
  vertical-align: middle !important;
}

#leftnav div[role="presentation"]>*,
#leftnav div[role="presentation"] {
  color: #fff;
  top: 0;
}

div[role="presentation"]>table {
  color: #333;
}

a[role="menuitem"]:hover {
  background-color: #4d4d4d;
}

.tabListStyle .is-selected .ms-Pivot-text {
  font-size: 14px;
  font-weight: 500;
}

span.k-link {
  font-size: 14px;
  font-weight: 600;
}

.ms-Pivot-link,
.is-selected {
  font-size: 13px;
}

.ms-Label {
  font-size: 12px;
  font-weight: 600;
}

.ms-Dropdown-caretDownWrapper {
  top: 0;
}

.ms-Pivot {
  margin-bottom: 8px;
  position: relative;
}

.tabListStyle {
  margin-left: -10px;
}

.k-grid td,
.k-header,
.k-i-plus {
  padding: 2px 0px 0px 10px;
}

.k-i-plus {
  padding: 2px !important;
}

.k-textbox {
  height: 24px !important;
}

.k-treeview {
  font-size: 12px !important;
}

.ms-Button--primary {
  color: #ffffff;
  background-color: #333333;
  border: #8a8886;
  height: 28px;
}

.ms-Button--default {
  height: 28px;
}

.ms-Button--primary:hover {
  background-color: #5e5a5a;
  border: #5e5a5a;
}

.ms-Button-flexContainer {
  font-size: 10px;
  font-weight: 400;
}

.k-checkbox {
  border-color: #8a8886;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #655656 !important;
  background-color: #655656 !important;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected>td {
  background-color: #dee2e6 !important;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-state-focus {
  border-color: #655656 !important;
}

.k-splitbar:active,
.k-splitbar.k-state-focus {
  color: #655656 !important;
  background: #655656 !important;
}

.k-splitbar.k-state-focused {
  color: #8f8b8b !important;
  background: #dfdbdb !important;
}

.k-splitbar {
  background: #dfdbdb;
}

.ms-Icon:hover {
  cursor: pointer;
}

#root div[class*="titleContainer"] a {
  background-image: url(Microsoft-logo_rgb_c-wht.png);
  background-position: left;
  background-repeat: no-repeat;
  background-size: 140px auto;
  margin-left: -20px;
}

.ms-Link {
  z-index: 9999;
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap {
  height: 25px;
}

.case-command-buttons {
  padding-left: 0;
}

.attachment-container,
.k-upload-button {
  font-family: "Segoe UI";
  font-size: 12px;
}

.attachment-container.k-upload {
  width: 300px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 2px;
}

.attachment-container.k-upload .k-dropzone {
  padding: 0;
}

.attachment-container .k-dropzone-hint {
  text-align: left;
  padding-left: 5px;
}

div[role="banner"] {
  background-color: #333333;
}

nav[role="navigation"] {
  background-color: #333333;
  min-height: 96vh;
  overflow: hidden;
  padding-top: 48px;
}

div[role="presentation"]>*,
div[role="presentation"] {
  color: #fff;
  top: 0;
}

div[role="presentation"]>table {
  color: #333;
}

a[role="menuitem"]:hover {
  background-color: #4d4d4d;
}

.ms-Pivot-text {
  font-size: 16px;
}

.ms-Pivot-link,
.is-selected {
  font-size: 13px;
}

.ms-TextField-field,
.k-detail-cell {
  font-size: 12px;
  height: 32px;
  color: #171717;
}

.ms-Dropdown-caretDownWrapper {
  top: 0;
}

/* .ms-TextField-fieldGroup {
  height: 30px;
} */

.ms-Pivot {
  margin-bottom: 5px;
  position: relative;
}

.overlay {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  cursor: pointer;
}

.k-i-plus {
  padding: 2px !important;
}

.k-textbox {
  height: 24px !important;
}

.k-treeview {
  font-size: 12px !important;
}

.ms-Button--primary:disabled {
  background-color: #c2c2c2 !important;
  border: #f3f2f1 !important;
}

.ms-Button-flexContainer {
  font-size: 12px;
  font-weight: 400;
}

.k-checkbox {
  border-color: #655656 !important;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #655656 !important;
  background-color: #655656 !important;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected>td {
  background-color: #dee2e6 !important;
}

.k-checkbox:focus {
  border: 2px solid #655656;
  box-shadow: none;
  padding: 7px;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-state-focus {
  border-color: #655656 !important;
  box-shadow: none !important;
  padding: 7px;
  outline: 1px solid #424242;
}

.attachment-container.k-state-disabled span,
.attachment-container.k-state-disabled .k-dropzone-hint {
  color: black
}

.ms-Icon:hover {
  cursor: pointer;
}

#root div[class*="titleContainer"] a {
  padding-left: 150px;
}

.ms-Link {
  z-index: 9999;
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap {
  height: 25px;
}

.react-autosuggest__container {
  position: relative;
  padding-right: 8px;
}

.react-autosuggest__input {
  width: 300px;
  font-family: "Segoe UI";
  font-weight: 300;
  height: 30px;
  font-size: 12px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 25px;
  width: 300px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: "Segoe UI";
  font-weight: 300;
  font-size: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 200px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.form-control {
  font-size: 11px !important;
  height: 2.3em !important;
}

.k-radio-list,
.k-radio-label {
  font-size: 12px !important;
  padding: 0px 0px 5px 0px !important;
}

.k-radio:checked,
.k-radio.k-checked {
  background-color: #333333 !important;
  border-color: #8a8886 !important;
}

.success-msg {
  color: #1e7d1e;
}

.error-msg {
  color: red;
  font-weight: 600;
  letter-spacing: 0.5px;
  word-break: break-word;
}

.warning-msg {
  color: #ffc000;
  font-weight: 600;
  letter-spacing: 0.5px;
  word-break: break-word;
}

.status-panel {
  background-color: #f2f2f2;
  margin: 10px 0px;
  padding: 10px !important;
  font-size: 12px;
  margin: 1px;
}

.kendogridchild {
  position: absolute;
  width: 100%;
}

.kendogridparent {
  height: 50px;
  width: 100%;
}

.channel-accounts .ms-ChoiceField {
  margin: 0;
}

div[aria-hidden="true"][role="tabpanel"] {
  display: none;
}

.ChannelReseller {
  padding-bottom: 10px;
}

.ChannelDistributor {
  padding-bottom: 5px;
  border-top: solid 1px lightgray;
  margin: 0px;
}

.channelLabels .ms-Label {
  font-size: 12px;
}

.channelcontacts .ms-Label {
  font-weight: 300;
}

#main div.caseIdStyle h1{
  font-size: large;
}

.caseIdStyle {
  padding: 8px 0px;
  font-weight: 600;
  float: left;
}

.caseStatusStyle {
  padding: 8px 16px 0px;
  font-weight: 500;
  float: right;
}

.k-ghost-splitbar .k-icon,
.k-splitbar .k-icon {
  font-size: 20px;
  display: block;
  cursor: pointer;
}

.css-14el2xx-placeholder {
  color: #727272 !important;
}

#gridTitle {
  color: #4d4d4d;
  font-size: 13px;
  font-weight: 700;
}

.k-dialog-buttongroup {
  border: none;
}

#legalEntityContent .k-splitter .k-scrollable {
  overflow-x: hidden;
}

#legalEntityContent .legal-entity-subtab button[type="button"] span {
  color: #4d4d4d;
    font-size: 13px;
    font-weight: 700;
  }

#gridTitleLegalEntity {
  color: #4d4d4d;
  padding: 8px 0px;
  margin-left: 22px;
  font-size: 13px;
  font-weight: 900;
}

.k-animation-container {
  overflow: visible !important;
  background-color: #fff;
}

.ms-Panel-main .k-i-sort-asc-sm {
  visibility: hidden;
}

.ms-Panel-main .k-i-more-vertical {
  visibility: hidden;
}

.ms-Panel-hideSelectAllCheckBox .k-grid-header-wrap table thead tr:first-child th:first-child input {
  visibility: hidden;
}

.ms-Panel-hideAllCheckBox .k-grid-table tbody tr td input {
  visibility: hidden;
}

#legalEntitySearchBar .ms-Label,
#productSelectionSearchBar .ms-Label {
  font-weight: normal;
  font-style: italic;
}

.ms-TextField-field::placeholder {
  color: #717171;
  font-size: 11px;
}

.centerAlign,
.centerAlign input {
  text-align: center !important;
}

.k-pager-numbers .k-link {
  color: #c34b42;
}

.k-pager-numbers a.k-link.k-state-selected,
.k-pager-numbers .k-link:focus {
  background-color: transparent;
  border: 1px solid #FF5661;
  z-index: auto;
}

.k-state-disabled.k-pager-nav:focus,
.k-pager-wrap .k-link:focus {
  background-color: transparent;
  border: 1px solid #000000;
}

.k-panelbar>.k-panelbar-header>.k-link.k-state-selected {
  background-color: #dbdbdb !important;
}

.k-panelbar>.k-panelbar-header>.k-link {
  color: #4d4d4d !important;
  font-size: 13px;
  font-weight: 900;
}

.k-grid-table tr.k-grouping-row td:last-child {
  display: none;
}

th.k-header.active>div>div {
  color: #ff6358;
}

.ms-Panel-scrollableContent .ms-Persona-coin {
  display: none;
}

.ms-Panel-scrollableContent .ms-Persona-details {
  padding: 0px;
}

.ms-Panel-scrollableContent .body .dividerStyle {
  height: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.caseButtonStyle {
  padding: 8px 16px 0px;
  float: right;
}

.productsearch {
  padding: 5px 0px 0px 5px;
}

.notAvailableTextMargin {
  margin: 4px 0 0 10px;
}

#searchAndSelectGrid .k-grid-content {
  max-height: 35vh;
}

.gridmandatoryField {
  color: red;
  font-size: 15px;
}

#primarylegalparentdiv {
  padding: 15px 0px 5px 0px;
  width: 100%;
}

#primarylegalparentdiv hr {
  color: #000000;
  height: 2px;
  width: 100%;
  margin: 0;
}

.title-bold {
  font-weight: 700 !important;
}

.vteam-section {
  margin-top: 20px;
  border-top: solid 1px lightgray;
}

.page-footer a:hover {
  cursor: pointer;
  color: #fff;
  background-color: #969494;
}

#dealOverviewAlign {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding-left: 8px;
}

#ProductSelectionViewGrids .k-grid-content {
  max-height: 35vh;
}

#ProductSelectionViewGrids .k-animation-container {
  z-index: 0 !important;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  .DealDetailsSearchGrid {
    height: 55vh;
    overflow-y: auto;
  }
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {

  .DealDetailsSearchGrid {
    height: 75vh;
    overflow-y: auto;
  }
}

.channel-grid-cell-content-align {
  vertical-align: top !important;
}

ul.names-list {
  list-style: none !important;
  padding-inline-start: 10px !important;
}

li.names-li-item::before {
  content: "\2022";
  color: #747373;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.revertMessage {
  font-weight: bold;
}

.listTitle {
  padding-top: 11px;
  color: rgb(161, 159, 157);
}

.listitems {
  padding-top: 2px;
  font-weight: normal;
}

.listParent {
  width: 300px;
}

.SectionHeader {
  font-size: 13px;
  font-weight: 900;
}

.viewFormContainer {
  color: #000;
}

#committedDealVolume .k-grid td {
  border-width: 0;
}

#committedDealVolume .k-grid th {
  border-width: 0;
}

#committedDealVolume .k-textbox {
  width: 50px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#statusDetail {
  float: right;
  padding-right: 1em;
}

#statusDetail span {
  padding-right: 5em;
  color: rgb(13, 112, 173);
  cursor: pointer;
}

#parentDetail {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding-left: 8px;
  padding-top: 10px;
}

.tableContent {
  display: table;
  height: 150px;
  padding: 5px;
}

.tablerow {
  display: table-row;
  line-height: 15px;
}

.tablecell {
  display: table-cell;
  line-height: 15px;
  padding: 5px;
}

.SectionHeader {
  font-size: 13px;
  font-weight: 900;
}

.add-new-customer-footer {
  margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
  right: 0px;
  bottom: -16px;
  float: right;
}

.productgroupStyle {
  padding-bottom: 10px;
  padding-left: 8px;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  #ProductDetails .k-grid-container {
    height: 25vh;
    overflow-y: auto;
  }

  #customersearch .k-grid .k-grid-container {
    max-height: 40vh !important;
    overflow-y: auto !important;
  }
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  #ProductDetails .k-grid {
    height: 50vh;
    overflow-y: auto;
  }

  #customersearch .k-grid .k-grid-container {
    max-height: 45vh !important;
    overflow-y: auto !important;
  }
}


.custom-kendo-grid-link {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none !important;
  color: #0078D4 !important;
  cursor: pointer;
}

#resetBtn .ms-Button-flexContainer {
  font-size: 14px;
}

#addResellerButton .ms-Button-flexContainer {
  border: 1px solid rgb(172, 172, 172);
  display: inline-flex;
}

#PanelTableContainer .ms-Dropdown-container {
  display: flex;
}

#PanelTableContainer .ms-MessageBar-dismissSingleLine {
  display: none;
}

#PanelFieldContainer .ms-MessageBar-dismissal {
  display: none;
}

#PanelTableContainer .configContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

#PanelTableContainer .k-grid-header .k-header {
  background-color: #fff;
}

#PanelTableContainer .k-grid th {
  border-width: 0;
}

#PanelTableContainer .k-grid td {
  border-width: 0;
}

#PanelTableContainer .k-grid {
  border: none;
}

#PanelTableContainer .k-alt {
  box-shadow: 0 0 0.8px 0 #8a8a8a;
}

.searchBoxContainer {
  margin-bottom: 10px;
}

#panelTableGrid .k-grid .k-grid-container {
  max-height: 50vh;
}

.ms-LabelValue {
  font-weight: 400;
}

.ms-LabelValueInvalid {
  font-weight: 400;
  color: red;
}

.panelFieldFooterContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.panelNavigationContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.NextButtonWithIcon .ms-Button-flexContainer {
  flex-direction: row-reverse;
}

#parentDetail {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding-left: 8px;
}

#statusDetail {
  float: right;
  padding-right: 1em;
}

#statusDetail span {
  padding-right: 5em;
  color: rgb(13, 112, 173);
  cursor: pointer;
}

.table-content {
  display: table;
  max-height: 150px;
  padding: 5px;
}

.table-row {
  display: table-row;
  line-height: 15px;
}

.table-cell {
  display: table-cell;
  line-height: 15px;
  padding: 5px;
}

.customerSearchBoxContainer {
  padding-top: 1.8rem;
  width: 650px;
}

.createCustomerCheckBox span {
  font-size: 11px !important;
}

.customer-filter {
  min-width: 12%;
  padding: 5px;
  float: right;
}

.tbl-info {
  font-size: 12px;
  background-color: #f3f2f1
}

.partner-case-detail {
  position: absolute;
  right: 0;
  margin: 8px;
  font-size: 14px;
  z-index: 99;
  cursor: pointer;
}

.partner-case-detail :hover {
  background-color: rgb(243, 242, 241);
  cursor: pointer;
}

.content-342 {
  display: block;
}

.ms-Dialog-title {
  color: #000 !important;
}

.ms-Dialog-lgHeader {
  border-top: 0 !important;
}

.ms-Dialog-main {
  max-width: 500px;
}

.create-customer-success {
  font-size: 12px !important;
}

.add-customer-form {
  padding-top: 2%;
}


.k-grid tr.k-alt,
.k-grid tr,
.k-master-row.k-alt .k-grid-content-sticky,
.k-master-row.k-alt .k-grid-row-sticky {
  background-color: #ffffff;
}

.k-master-row.k-alt .k-grid-content-sticky,
.k-master-row.k-alt .k-grid-row-sticky {
  background-color: inherit;
}

.k-master-row {
  font-size: 12px;
  height: 36px;
}

.k-master-row td {
  border-bottom: 1px #EDEBE9 solid !important;
}

.k-grid td {
  border: none;
}

.k-grid .k-grid-content-sticky,
.k-grid .k-grid-footer-sticky {
  z-index: 0;
}

.k-grid-header .k-header>.k-link {
  padding-right: 40px;
  padding-left: 15px;
}

.k-i-more-vertical::before {
  content: "\e12b";
}

.dashboardRadioButton label[class^="ms-ChoiceField-field field-"]::before,
.dashboardRadioButton label[class^="ms-ChoiceFieldLabel field-"]::after,
.dashboardRadioButton label[class^="ms-ChoiceField-field is-checked field-"]::before,
.dashboardRadioButton label[class^="ms-ChoiceFieldLabel is-checked field-"]::after {
  width: 16px;
  height: 16px;
}

.dashboardRadioButton label[class^="ms-ChoiceField-field field-"]:hover::after {
  border-radius: 100%;
  height: 6px;
  width: 6px;
}

.dashboardRadioButton label[class^="ms-ChoiceField-field is-checked field-"]::after {
  top: 3px;
  left: 3px;
}

.choosecolumns span[class^="ms-Button-label label-"] {
  font-size: 14px;
}

.k-grid table {
  min-width: 100%;
}

.editablegridcontainer {
  overflow-x: auto;
  overflow-y: auto;
  padding-right: 4px;
  padding-bottom: 10px;
  padding-left: 4px;
  position: relative;
}

.editablegridcontainer .ms-DetailsHeader {
  padding-top: 0px;
}

#BidgridDashboard .k-grid .k-hierarchy-cell {
  content-visibility: hidden;
}

#productgroupdetails .k-animation-container {
  z-index: 0 !important;
}


#partnerDetails i {
  color: #0078D4;
  vertical-align: middle;
  padding-left: 8px
}

#CustomerSearchPaper,
#DealDeatilsPaper,
#PatnerDetailsPaper {
  box-shadow: 0 2px 4px rgb(0 0 0 / 14%), 0 0 2px rgb(0 0 0 / 12%);
  width: fit-content;
  padding: 8px 8px 12px;
}

.CustomViewModeComponentLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

#PrimaryResellerContainer {
  margin-top: 0px;
}

#BGComboBox .ms-ComboBox-Input {
  font-size: 11px;
}

.mark {
  background-color: yellow !important;
  color: black;
  padding: 0 !important;
}

.k-grid-header .k-header .k-link .k-icon {
  position: absolute;
  left: -8px;
  top: 15px;
}

.breakWord20 {
  overflow: visible !important;
  white-space: normal !important;
  width: 200px;
}

.k-grid-norecords td {
  padding: 16px !important;
}

.k-grid-content {
  padding-bottom: 8px
}

.add-to-case-button {
  right: 0;
  position: absolute;
  top: 100px;
  margin-top: -8px;
}

#RefreshButton {
  margin-right: 200px;
  margin-top: -53px;
  margin-bottom: 16px;
}

#RefreshIconButton {
  margin-top: -53px;
  margin-bottom: 16px;
}

label[for="WebpageURL"]~div.no-webpage-available,
label.no-webpage-available,
input.no-webpage-available {
  color: rgb(198, 198, 198);
  border-color: rgb(198, 198, 198);
}

/** fadeInLeft **/

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px);
  }

  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}

@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px);
  }

  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-100px);
    -moz-transform: translatex(-100px);
    -o-transform: translatex(-100px);
    transform: translatex(-100px);
  }

  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}

.in-left {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-duration: 1s;
  animation-delay: 1s;
}

/* accessibility - filter menu dropdown text color contrast */
ul.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background-color: #DE3629;
  outline: 1px transparent solid;
}

.otle-channel .otle-reseller {
  width: 100%
}

.channel-grid div[role="gridcell"] span[class^="dynamicSpanStyle"] {
  white-space: normal;
}