.gridStyle.k-grid{
    border-width: 0px;
}

.gridStyle.k-grid td{
    border-width: 0 0 1px 0px !important;
    height: 43px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
}

.gridStyle.k-grid th{
    border-width: 0 0 1px 0px !important;
} 

.gridStyle.k-grid .k-grid-content {
    overflow-y: unset;
}

.gridStyle.k-grid .k-grid-header-wrap, .gridStyle .k-grid-footer-wrap{
    border-width: 0px;
}

.gridStyle.k-grid .k-grid-header{
    border-width: 0px;
    background-color: #DBDBDB;
}

.gridStyle.k-grid .k-grid-header .k-i-sort-asc-sm, .gridStyle .k-grid-header .k-i-sort-desc-sm, .gridStyle .k-grid-header .k-sort-order{
    color: #8A8886;
    margin-left: 10px;
}

.gridStyle.k-grid .k-icon{
    margin-right: -17px;
    width: 14px;
    height: 12px;
}

.gridStyle.k-grid.gridColumn{
    background-color: white;
}

.gridStyle .k-grid-header thead{
    display: none;
}
    
.add-to-case-panel .k-upload .k-dropzone .k-dropzone-hint {
    font-size: 12px;
    font-weight: 600;
    padding-left: 1.3rem;
    overflow: unset;
    text-overflow: unset;
    text-align: right;
}

.add-to-case-panel .k-button, .k-button-secondary {
    border-color: initial;
    color: blue;
    background-color: inherit;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
    padding-right: 1.7rem !important;
}

.add-to-case-panel .k-button {
  border-style: none;
}

.add-to-case-panel .k-button:focus, .k-button.k-state-focus, .k-button.k-state-focused, .k-button-secondary:focus, .k-button-secondary.k-state-focus, .k-button-secondary.k-state-focused {
    box-shadow: none;
}

    


